import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "../components/header_image"

const Header = ({ siteTitle }) => (
  <header className="header-main">
    <div className="header-mainnav">
      <div className="header-logo">
          <Link to="/">
            <Image />
          </Link>
      </div>
      <div className="header-nav">
        <ul>
          <li><Link to="/projekte">Projekte</Link></li>
          <li><Link to="/buero">Büro</Link></li>
          <li><Link to="/kontakt">Kontakt</Link></li>
        </ul>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
