import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="maxWidth">
      <div>
        © {new Date().getFullYear()} Ruch & Partner mbB
      </div>
      <div>
        <Link to="/kontakt" className="secText">Kontakt</Link>  | <Link to="/kontakt/impressum" className="secText">Impressum</Link> | <Link to="/kontakt/datenschutz" className="secText">Datenschutz</Link>
      </div>
    </div>
  </footer>
)

export default Footer
